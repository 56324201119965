// Cart Actions
export const ADD_TO_CART = 'ADD_TO_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';

//Action Types
export const SYNC_QUANTITY = 'SYNC_QUANTITY';
export const CART_TOTAL = 'CART_TOTAL';
export const EXTERNAL_ORDER = 'EXTERNAL_ORDER';
export const CART_ACCESS = 'CART_ACCESS';
export const REGION_COUNTRY = 'REGION_COUNTRY'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const SEARCH_PRODUCTS_FROM_HEADER = 'SEARCH_PRODUCTS_FROM_HEADER';
export const FETCH_PRODUCT_OPTIONS = 'FETCH_PRODUCT_OPTIONS';
export const FETCH_PRODUCT_OPTIONS_ERRORED = 'FETCH_PRODUCT_OPTIONS_ERRORED';
export const CART_SUB_TOTAL = 'CART_SUB_TOTAL';
export const TOTAL_SHIPPING = 'TOTAL_SHIPPING';
export const CART_ACCESS_COUNTRY = 'CART_ACCESS_COUNTRY';
export const TOTAL_TAX = 'TOTAL_TAX';
export const EXTERNAL_ORDER_ERROR = 'EXTERNAL_ORDER_ERROR';
export const TAX_RATE = 'TAX_RATE';
export const SHIPPING_METHODS = 'SHIPPING_METHODS';
export const TAX_RESPONSE = 'TAX_RESPONSE'
export const CUSTOMER_IP = 'CUSTOMER_IP';
export const LOGGED_IN_CUSTOMER = 'LOGGED_IN_CUSTOMER';
export const REGISTER_CUSTOMER_RES = 'REGISTER_CUSTOMER_RES';
export const ORDERS = 'ORGER_LIST';
export const ORDER = 'ORGER_SINGLE';
export const PREPARE_ORDER = 'PREPARE_ORDER';
export const PREPARE_ORDER_EXTERNAL = 'PREPARE_ORDER_EXTERNAL'
export const ORDER_PLACED = 'ORDER_PLACED';
export const ORDER_ERRORED = 'ORDER_ERRORED';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const TOKEN_VERIFICATION = 'TOKEN_VERIFICATION';
export const REGION_FAILED = 'REGION_FAILED';



// global actions
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const IS_UPDATED = 'IS_UPDATED'
export const IN_PROCESS = 'IN_PROCESS'
export const ORDER_PROCESS = 'ORDER_PROCESS'
export const ORDER_ERROR = 'ORDER_ERROR'
export const TAX_ERROR = 'TAX_ERROR'
export const SHIPPING_ERROR = 'SHIPPING_ERROR'
export const PASSWORD_UPDATE_STATUS = 'PASSWORD_UPDATE_STATUS'
export const PASSWORD_FORGET_STATUS = 'PASSWORD_FORGET_STATUS'
export const SEO_DATA = 'SEO_DATA'


