import React, { Component, Fragment } from 'react';

import { Link, NavLink  } from 'react-router-dom'
import LogoutButton from './logout-button'
import { TO_CUSTOMER_ACCOUNT, TO_CUSTOMER_ORDERS, TO_CHANGE_PASSWORD, TO_RETURN, TO_CART } from '../helpers/routesConstants';
import Button from 'react-bootstrap/esm/Button';

export default class NotPaidComponent extends Component {
    onClickHandle = (e) => {
        e.preventDefault();
        window.onbeforeunload = null;
        window.location.pathname = TO_CART
    }
    render() {
        return (
            <Fragment>
                <div className="image d-flex justify-content-center  mt-2 mb-2"><img src="/assets/img/notPaid.svg" alt="not paid" className="img-fluid" /></div>
                <div class="d-flex justify-content-center mt-2 mb-2">
                    <Button onClick={this.onClickHandle} variant="warning">
                        Try Again
                    </Button>
                </div>
            </Fragment>
        )
    }
}
