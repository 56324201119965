import React, { useState } from "react";

const Toggle = (props) => {
    const [selectedOption, setSelectedOption] = useState("Yes");
    const containerStyle = {
        display: "flex",
        justifyContent: "flex-end"
    }
    const yesContainerStyle = {
        display: "flex",
        width: "40px",
        border: "1px solid",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: selectedOption === "Yes" ? "#E8E9EA" : null,
        cursor:"pointer"
    }
    const noContainerStyle = {
        display: "flex",
        width: "40px",
        border: "1px solid",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: selectedOption === "No" ? "#E8E9EA" : null,
        cursor:"pointer"
    }
    return (
      <div  style={containerStyle}>
        <div style={noContainerStyle} onClick={() => {setSelectedOption("No"); props.onSelect("No")}}>No</div>
        <div style={yesContainerStyle} onClick={() => {setSelectedOption("Yes"); props.onSelect("Yes")}}>Yes</div>
      </div>
    );
  };
    
  export default Toggle;