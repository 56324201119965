import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { TO_GET_A_QUOTE } from '../helpers/routesConstants';
import 'react-confirm-alert/src/react-confirm-alert.css';
export default class Button extends Component {
  
  render() {
    return (
      <Fragment>
        {
          this.props.disabled ? 
            <a title="Get A quote" href="https://intrepidcs.com/sales/" className="quote-btn"> <button type="button" className="btn btn-template-outlined cart-button">Get a quote</button></a>
          :
            <button disabled={this.props.disabled} type="button" className="btn btn-template-outlined cart-button" onClick={this.props.onClick}><i className="fa fa-shopping-cart"></i> Add to cart</button>
        }
      </Fragment>
      
    )
  }
}
