//API Constants
// var API_URL = ''
// if (window.location.host.indexOf('localhost') > -1) {
//     API_URL = 'http://localhost:3004'; //server URL
// } else if (window.location.host.indexOf('store.intrepidcs.com') > -1) {
//     API_URL = 'https://www.yourbluefuture.com'; //server URL
// } else if (window.location.host.indexOf('dev.yourbluefuture.com') > -1) {
//     API_URL = 'https://dev.yourbluefuture.com'; //production URL
// } else if (window.location.host.indexOf('staging.yourbluefuture.com') > -1) {
//     API_URL = 'https://staging.yourbluefuture.com'; //production URL
// } else if (window.location.host.indexOf('yourbluefuture') > -1) {
//     API_URL = 'https://www.yourbluefuture.com'; //production URL
// }
import {TO_HOME, TO_CATEGORY, TO_CART, TO_CUSTOMER_ACCOUNT} from './routesConstants';
const API_URL = process.env.REACT_APP_SERVER_URL;

export const CLIENT_KEY_RECAPTCHA = process.env.REACT_APP_CLIENT_KEY_RECAPTCHA;

export const GET_IP = 'https://api.ipify.org/?format=json';
export const PRODUCT_LIST = API_URL + '/api/blue-sky/product-list';
export const PRODUCT_OPTIONS = API_URL + '/api/blue-sky/product-options';
export const CATEGORY_LIST = API_URL + '/api/blue-sky/category-list';
export const UPDATE_ACTIVITY_LOG = API_URL + '/api/blue-sky/save-activily-log-data';
export const payment_gateway = API_URL + '/api/blue-sky/payment-gateway';
export const REGISTER_CUSTOMER = API_URL + '/api/blue-sky/register-customer';
export const UPDATE_CUSTOMER = API_URL + '/api/blue-sky/update-customer';
export const UPDATE_PASSWORD_CUSTOMER = API_URL + '/api/blue-sky/update-password-customer';
export const FORGET_PASSWORD_CUSTOMER = API_URL + '/api/blue-sky/forget-password-customer';
export const CHANGE_PASSWORD_CUSTOMER = API_URL + '/api/blue-sky/change-password-customer';
export const LOGIN_CUSTOMER = API_URL + '/api/blue-sky/login-customer';
export const ORDER_LIST = API_URL + '/api/blue-sky/order-list';
export const GET_ORDER = API_URL + '/api/blue-sky/order/';
export const GET_TAX = API_URL + '/api/blue-sky/calculate-tax-rate';
export const GET_SHIPPING_METHODS = API_URL + '/api/blue-sky/get-available-shipping';
export const PLACE_ORDER = API_URL + '/api/blue-sky/place-order';
export const GET_ORDER_FOR_EXTERNAL = API_URL + '/api/blue-sky/external-checkout-order/';
export const PLACE_ORDER_FOR_EXTERNAL = API_URL + '/api/blue-sky/place-external-order';
export const IMAGE_BASE_URL = API_URL + '/files/images/';
export const REGION_CONTROL = API_URL + '/api/blue-sky/region-control/';
export const TOKEN_CONFIRMATION = API_URL + '/api/blue-sky/token-verification/';
export const MINIMUM_ORDER_TOTAL = 99.99;
export const MAXIMUM_ORDER_TOTAL = 24999.00;





//general constants

export const API_LOGIN = process.env.REACT_APP_AUTHORIZE_API_KEY;
export const API_PUBLIC_KEY = process.env.REACT_APP_AUTHORIZE_PUBLIC_KEY;


export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const DEV_MODE = !!process.env.REACT_APP_DEV_MODE;


// console.log(API_LOGIN, API_PUBLIC_KEY);

export const Store_Title = 'Intrepid Control Systems';
export const CUSTOMER_COOKIE = 'isccustomer';
export const DATE_FORMAT = 'yyyy/mm/dd';
export const NOT_ALLOWED_COUNTRY_CODES = ['SE', 'ES', 'SI', 'SK', 'RO', 'PT', 'PL', 'NL', 'MT', 'LU', 'LT', 'LV', 'IT', 'IE', 'HU', 'GR', 'DE', 'FR', 'FI', 'EE', 'DK', 'CZ', 'CY', 'HR', 'BG', 'BE', 'AT', 'JP', 'IN', 'KR', 'CN', 'UK', 'GB', 'CU', 'KP', 'IR', 'CH', 'IS', 'LI']
export const IP_STACK_PUBLIC_KEY = process.env.REACT_APP_IP_STACK_PUBLIC_KEY

//Allowed hosts
export const ALLOWED_HOSTS = {
    localhost: 'localhost',
    development: 'store-dev.yourbluefuture.com',
    preprod: 'store.yourbluefuture.com',
    production: 'store.intrepidcs.com',
    pentest: 'pentest-store.yourbluefuture.com'
};


//LocalStorage items
export const SEND_STATISTICS = 'sendStatistics';
export const CART_STATE_PERSIST = 'cartStatePersist';

export const PRIVACY_POLICY = 'privacy-policy';
export const USER_DATA = {
    name: 'anonymous',
    type: 'Store'
}
export const COOKIE_NAME = 'acceptCookie';
export const ACCEPT_SCRIPT_URL = DEV_MODE && ENVIRONMENT.toLowerCase() === 'development'
    ? "https://jstest.authorize.net/v1/Accept.js"
    : "https://js.authorize.net/v1/Accept.js";
export const SCRIPT_TYPE = "text/javascript";
export const SCRIPT_CHARSET = "utf-8";
export const SCRIPT = 'script';
export const ALL = 'all';

//BreadCrumbs
export const globalBreadCrumb = {label: 'HOME', to: TO_HOME };
export const cartBreadCrumb = { label: 'CART', to: TO_CART };
export const myAccountBreadCrumb = { label: 'MY ACCOUNT', to: TO_CUSTOMER_ACCOUNT };
export const productDetailBreadcrumb = (selectedCategory, ProductCode, allProduct) => [
    globalBreadCrumb,
    { label: 'PRODUCT', to: TO_CATEGORY + allProduct.toString() },
    { label: selectedCategory.name.toUpperCase(), to: TO_CATEGORY + selectedCategory.id },
    { label: ProductCode },
];
export const productDetailWithoutCategoryBreadcrumb = (ProductCode) => [
    globalBreadCrumb,
    { label: ProductCode },
];
export const cartDetailBreadCrumbs = [
    globalBreadCrumb,
    { label: 'CART' }
];
export const cartDetailCheckoutBreadCrumbs = [
    globalBreadCrumb,
    cartBreadCrumb,
    { label: 'CHECKOUT CONFIRM' },
];
export const allProductCategoryViewBreadcrumb = [
    globalBreadCrumb,
    { label: 'ALL PRODUCTS' },
];
export const categoryViewBreadcrumb = (selectedCategory, allProduct) => [
    globalBreadCrumb,
    { label: 'PRODUCT', to: TO_CATEGORY + allProduct.toString() },
    { label: selectedCategory.name.toUpperCase() },
];
export const checkoutBillingAddressBreadCrumb = [
    globalBreadCrumb,
    cartBreadCrumb,
    { label: 'CHECKOUT BILLING ADDRESS' }
];
export const checkoutPaymentMethodBreadCrumb = [
    globalBreadCrumb,
    cartBreadCrumb,
    { label: 'CHECKOUT PAYMENT METHOD' }
];
export const checkoutShippingAddressBreadCrumb = [
    globalBreadCrumb,
    cartBreadCrumb,
    { label: 'CHECKOUT SHIPPING ADDRESS' }
];
export const checkoutShippingDetailBreadCrumb = [
    globalBreadCrumb,
    cartBreadCrumb,
    { label: 'CHECKOUT SHIPPING DETAIL' }
];
export const myAccountBreadCrumbs = [
    globalBreadCrumb,
    { label: 'MY ACCOUNT' }
];
export const customerChangePasswordBreadCrumb = [
    globalBreadCrumb,
    myAccountBreadCrumb,
    { label: ' CHANGE PASSWORD' }
];
export const customerOrdersBreadCrumb = [
    globalBreadCrumb,
    myAccountBreadCrumb,
    { label: ' MY ORDERS' }
];
export const aboutUsBreadCrumb = [
    globalBreadCrumb,
    { label: ' ABOUT US' }
];
export const faqBreadCrumb = [
    globalBreadCrumb,
    { label: 'FAQ' },
];
export const priceCartFaqBreadCrumb = [
    globalBreadCrumb,
    { label: `CAN'T SEE PRICES OR ADD ITEMS TO CART?` },
];
export const howToOrderBreadCrumb = [
    globalBreadCrumb,
    { label: `HOW TO ORDER?` },
];
export const privacyPolicyBreadCrumb = [
    globalBreadCrumb,
    { label: 'PRIVACY POLICY' },
];
export const returnBreadCrumb = [
    globalBreadCrumb,
    { label: 'RETURNS' },
];
export const shippingBreadCrumb = [
    globalBreadCrumb,
    { label: 'SHIPPING' },
];
export const termAndConditionBreadCrumb = [
    globalBreadCrumb,
    { label: 'TERMS AND CONDITIONS' },
];