import React, { Component } from 'react';
import SideBar from '../../components/side-bar';
import Scroll from 'react-scroll';
import { Link } from 'react-router-dom';
import { TO_CUSTOMER_ACCOUNT, TO_CUSTOMER_ORDERS, TO_GET_A_QUOTE, WWW_LINK } from '../../helpers/routesConstants';
import { generateBreadcrumbs } from '../../helpers/utility';
import { howToOrderBreadCrumb, priceCartFaqBreadCrumb } from '../../helpers/constants';

class HowToOrderContainer extends Component {
    constructor() {
        super();
    }
    componentWillMount() {
        Scroll.animateScroll.scrollToTop();
    }
    render() {
        return (
            <div id="content">
                <div className="container">
                    <div className="row bar">
                        <SideBar />
                        <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <p className="product-map-arrow">
                                {generateBreadcrumbs(howToOrderBreadCrumb)}
                            </p>
                            <h1 className="uppercase h4 title-padding">To Place An Order, Request a Quote, or Inquire about Pricing:</h1>

                            <ul className="ml-3">
                                <li>
                                    Email to <a href="mailto:icsorders@intrepidcs.com">icsorders@intrepidcs.com</a>, or
                                </li>
                                <li>
                                    Call us at <a href="tel:+15867317950">+1 586-731-7950 ext. 2</a>, or
                                </li>
                                <li>Fax to +1 586-731-2274</li>
                                <li>
                                    If paying by credit card, we can send a payment link once we have entered your order.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HowToOrderContainer;
