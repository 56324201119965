import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrderSummary from '../../components/order-summary';
import { Link } from 'react-router-dom'
import { TO_CART, TO_CHECKOUT_SHIPPING, TO_CHECKOUT_USER_DETAIL, TO_CHECKOUT_BILLING_ADDRESS, TO_CUSTOMER_ACCOUNT, TO_EXTERNAL_CHECKOUT, TO_EXTERNAL_CHECKOUT_BILLING_ADDRESS } from '../../helpers/routesConstants';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';
import NavLink from 'react-router-dom/NavLink';
import { PREPARE_ORDER, PREPARE_ORDER_EXTERNAL } from '../../helpers/actionConstants';
import store from '../../store';
import { required,max25, max20, min3, min2, max2, max16, phoneFormat, email, STATE_LIST } from '../../helpers/form-validation';
import Scroll from 'react-scroll';
import { cartTotal, totalShipping, totalTax } from '../../actions/globalActions';
import { checkCartForFreeShipping } from '../../helpers/utility';
import { getOrderForExternal } from '../../actions/orderAction';
import { Modal, Button } from "react-bootstrap";
import { TO_LOGOUT, TO_HOME } from '../../helpers/routesConstants';


var _ = require('lodash');
require('dotenv').config()
const { getData, overwrite } = require('country-list');

class CheckoutUserDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.zipFormat = this.zipFormat.bind(this);
        this.PhoneFormat = this.PhoneFormat.bind(this);
        this.state = {
            externalOrderCustomer: {},
            shouldRenderPopup: false,
            showModal: false,
            orderId:null,
            order:{},
            orderhash:null,
            billingInformation:{
                BillingFirstName : "",
                BillingLastName : "",
                BillingAddress1 : "",
                BillingAddress2 : "",
                BillingPhoneNumber : "",
                BillingCompanyName : "",
                BillingCountry : "",
                BillingState : "",
                BillingCity : "",
                BillingPostalCode : "",
            },
            error:{},
            countryList: getData(),
        }
    }
    
   
    handleChange(event) {
        if(event.target.name === "PhoneNumber")
        {
            var x = event.target.value
            event.target.value = ''
            x = x.split('');
            x.forEach(number => {
                var z = number.charCodeAt()
                if (!(z >= 48 && z <= 57)) {
                    if (z != 43 && z != 120 && z != 88 && z != 32 && z != 46 && z != 45 && z != 43 && z != 40 && z != 41) {
                        // event.preventDefault();
                        
                    }
                    else{
                        event.target.value=	event.target.value + number
                    }
                }
                else{
                    event.target.value=	event.target.value + number
                }

            });                                                 
        }
        let billingInformation = { ...this.state.billingInformation };
        billingInformation[event.target.name] = event.target.value;
        this.setState({ billingInformation });
    }
    handleUpdateSubmit = (event) => {
        event.preventDefault();
        this.form.validateAll();
        if (this.form.getChildContext()._errors.length === 0) {
            let obj = {
                BillingFirstName : this.state.billingInformation.BillingFirstName,
                BillingLastName : this.state.billingInformation.BillingLastName,
                BillingAddress1 : this.state.billingInformation.BillingAddress1,
                BillingAddress2 : this.state.billingInformation.BillingAddress2,
                BillingPhoneNumber : this.state.billingInformation.BillingPhoneNumber,
                BillingCompanyName : this.state.billingInformation.BillingCompanyName,
                BillingCountry : this.state.billingInformation.BillingCountry,
                BillingState : this.state.billingInformation.BillingState,
                BillingCity : this.state.billingInformation.BillingCity,
                BillingPostalCode : this.state.billingInformation.BillingPostalCode,
            }
            store.dispatch({ type: PREPARE_ORDER_EXTERNAL, payload: obj });
            this.props.history.push(TO_EXTERNAL_CHECKOUT + this.state.orderhash);
        } else {
            console.error('have errors', this.form.getChildContext()._errors);
        }
    };
    async componentDidMount() {
        Scroll.animateScroll.scrollToTop();
        let sortedCountryList = _.sortBy(this.state.countryList, ['name'])
        let href = window.location.href
        let orderId = href.substring(href.lastIndexOf('/') + 1)
        let orderhash = orderId
        orderId = parseInt(atob(orderId))
        this.setState({ countryList: sortedCountryList,orderId:orderId,orderhash:orderhash });
		try {
            const response = await store.dispatch(getOrderForExternal(orderId));
            this.setState({externalOrderCustomer: response.data});
            console.log(response); 
        } catch (error) {
            console.error(error);
        }
    }

    componentDidUpdate(prevProps) {
        const {externalOrderCustomer, shouldRenderPopup} = this.state;
        const {customer, isLoggedIn} = this.props;
        if (!shouldRenderPopup && customer && customer.CustomerID && externalOrderCustomer && externalOrderCustomer.CustomerID && externalOrderCustomer.CustomerID !=  customer.CustomerID ) {
            this.setState({shouldRenderPopup: true, showModal: true});
        }
        if (isLoggedIn && !customer && externalOrderCustomer && externalOrderCustomer.CustomerID) {
            this.handleClose();
        }
        if(prevProps.externalOrder !== this.props.externalOrder){
            this.setState({
                order:this.props.externalOrder,
                error:{},
                billingInformation:{
                    BillingFirstName : this.props.externalOrder.BillingFirstName,
                    BillingLastName : this.props.externalOrder.BillingLastName,
                    BillingAddress1 : this.props.externalOrder.BillingAddress1,
                    BillingAddress2 : this.props.externalOrder.BillingAddress2,
                    BillingPhoneNumber : this.props.externalOrder.BillingPhoneNumber,
                    BillingCompanyName : this.props.externalOrder.BillingCompanyName,
                    BillingCountry : this.props.externalOrder.BillingCountry,
                    BillingState : this.props.externalOrder.BillingState,
                    BillingCity : this.props.externalOrder.BillingCity,
                    BillingPostalCode : this.props.externalOrder.BillingPostalCode,  
                }
            })
        }
        if(prevProps.externalOrderError !== this.props.externalOrderError){
            this.setState({
                error:this.props.externalOrderError,
                order:{}
            })
        }
        if (this.props.orderPlaceError && this.props.orderPlaceError.code !== prevProps.orderPlaceError.code) {
            this.setState({ orderRes: this.props.orderPlaceError }) 
        }
        
    }
    zipFormat(event) {
        var entry = event.key
        var format = /^\w+([\s-_]\w+)*$/;
        if (!(entry.match(format)) && event.key != '-' && event.key != ' ') {
            event.preventDefault();
        }
    }
    PhoneFormat(event) {
        var x = event.key;
        // console.log(x)
        if (!(x >= 0 && x <= 9)) {
            if (x != '+' && x != '-' && x != '('  && x != ')'  && x != '.'  && x != 'x'  && x != 'X' && x!=' ') {
                event.preventDefault();
            }
        }
    }
    handleClose = () => {
		this.setState({ showModal: false });
        this.props.history.push(TO_HOME);
	};
    handleLogout = () => {
        this.props.history.push(TO_LOGOUT);
	};
    renderPopup() {
        return (
            <Modal  show={this.state.showModal} onHide={this.handleClose} centered bsSize="lg">
				<Modal.Header closeButton>
					<Modal.Title>Invalid Customer</Modal.Title>
				</Modal.Header>
					<Modal.Body>
					    <p>This order belongs to a different customer</p>
					</Modal.Body>
					<Modal.Footer>
					<div className="d-flex justify-content-between">
						<Button style={{marginRight: "10px"}} variant="secondary"  onClick={this.handleClose} className="modal-buttons cancel-button">
							Cancel
						</Button>
						<Button variant="primary" onClick={this.handleLogout} className="modal-buttons">
							Logout
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
        );
      }
    render() {
        const {shouldRenderPopup} = this.state;
        overwrite([{
            code: 'AX',
            name: 'Aland Islands'
        }, {
            code: 'CW',
            name: 'Curacao'
        }, {
            code: 'CI',
            name: 'Ivory Coast'
        }, {
            code: 'LA',
            name: 'Laos'
        }, {
            code: 'RE',
            name: 'Reunion'
        }, {
            code: 'BL',
            name: 'Saint Barthelemy'
        }, {
            code: 'MF',
            name: 'Saint Martin (French)'
        }, {
            code: 'SX',
            name: 'Sint Maarten (Dutch)'
        }
        ])
        let countries = this.state.countryList.filter(country => {
            return country.code != 'CU' && country.code != 'IR' && country.code != 'KP' && country.code != 'US' && country.code !=  'SY' && country.code !=  'BY' && country.code !=  'RU' && country.code !=  'SD' && country.code !=  'SS'
        })
        let filteredCountried = this.state.countryList.filter(country => {
            return country.code == 'US'
        })
        countries.unshift(filteredCountried[0]);
        return (
            <div id="content">
                <div className="container">
                    <div className="row">
                        <div id="checkout" className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        {shouldRenderPopup && this.renderPopup()}
                            {
                                (Object.keys(this.state.error).length !==0 && this.state.error.isPaid) && (
                                    <div className="mt-5 mb-3 d-flex justify-content-center align-items-bottom mt-3">
                                        <p>This order has already been paid. Thank you!</p>                      
                                    </div>
                                )
                            }
                            {
                                (Object.keys(this.state.error).length !==0 && !this.state.error.isPaid) && (
                                    <div className="mt-5 mb-3 d-flex justify-content-center align-items-bottom mt-3">
                                        <p>This page in unavaiable because {this.state.error.msg}</p>                      
                                    </div>
                                )
                            }
                            {Object.keys(this.state.order).length !== 0 && (
                                <div className="text-center align-items-bottom mt-3">
                                    <p>Your Payment Amount is <b>${this.state.order && this.state.order.PaymentAmount && this.state.order.PaymentAmount.toFixed(2)} USD</b> for Order#: <b>{this.state.orderId}</b></p>                      
                                </div>
                            )}
                              {
                               Object.keys(this.state.order).length !== 0 && (
                            <div className="box-external border-bottom-0">
                                <ul className="nav nav-pills nav-fill">
                                    <li className="nav-item"><NavLink to={TO_EXTERNAL_CHECKOUT_BILLING_ADDRESS + this.state.orderhash} activeClassName='active' className="nav-link"> <i className="fa fa-address-card"></i><br></br>Billing Address</NavLink></li>
                                    <li className="nav-item"><a href="javascript:void(0)" className="nav-link disabled"><i className="fa fa-money"></i><br></br>Payment Method</a></li>
                                </ul>
                                <Form ref={c => { this.form = c }} onSubmit={this.handleUpdateSubmit.bind(this)}>
                                    <div className="content">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">First Name</label>
                                                    <Input validations={[required, max20]} name="BillingFirstName" onChange={this.handleChange} value={this.state.billingInformation.BillingFirstName} id="firstname" type="text" className="form-control"></Input>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="lastname">Last Name</label>
                                                    <Input validations={[required, max20]} name="BillingLastName" onChange={this.handleChange} id="lastname" value={this.state.billingInformation.BillingLastName} type="text" className="form-control"></Input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="company">Company</label>
                                                    <Input name="BillingCompanyName"  onChange={this.handleChange} id="company" type="text" value={this.state.billingInformation.BillingCompanyName} className="form-control"></Input>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="company">Address 1</label>
                                                    <Input validations={[required]} name="BillingAddress1" onChange={this.handleChange} id="address1" value={this.state.billingInformation.BillingAddress1} type="text" className="form-control"></Input>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="street">Address 2</label>
                                                    <Input name="BillingAddress2" onChange={this.handleChange} id="address2" type="text" value={this.state.billingInformation.BillingAddress2} className="form-control"></Input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="city">City</label>
                                                    <Input validations={[required]} name="BillingCity" onChange={this.handleChange} id="city" value={this.state.billingInformation.BillingCity} type="text" className="form-control"></Input>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="state">State</label>
                                                    <Input name="BillingState" onChange={this.handleChange} id="state" value={this.state.billingInformation.BillingState} type="text" className="form-control"></Input>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="zip">ZIP</label>
                                                    <Input name="BillingPostalCode" onChange={this.handleChange} onKeyPress={this.zipFormat} id="zip" value={this.state.billingInformation.BillingPostalCode} type="text" className="form-control"></Input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="country">Country</label>
                                                    <Select validations={[required]} name="BillingCountry" onChange={this.handleChange} value={this.state.billingInformation.BillingCountry} id="country" className="form-control">
                                                        <option value="" disabled>Country</option> 
                                                        {
                                                            countries
                                                                .map(function (country) {
                                                                    return <option key={country.code}
                                                                        value={country.code}>{country.name}</option>;
                                                                })
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="PhoneNumber">Phone Number</label>
                                                    <Input validations={[required, max25]} name="BillingPhoneNumber" onKeyPress={this.PhoneFormat} value={this.state.billingInformation.BillingPhoneNumber} onChange={this.handleChange} id="PhoneNumber" type="text" className="form-control"></Input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mgTp30">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <button type="submit" className="btn btn-template-outlined pull-right"> Continue to Payment <i className="fa fa-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                             )}
                        </div>
                        {/* <div className="col-lg-3">
                            <OrderSummary showTaxAndShipping={false} />
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

const stateMap = (state) => {
    return {
        global: state.global,
        products: state.product.products,
        externalOrder: state.order.externalOrder,
		externalOrderError: state.order.externalOrderError,
        orderToBePlace: state.order.orderToBePlace,
        customer: state.customer.customer.result,
        cartItems: state.cart,
        isLoggedIn: state.global.isLoggedIn,
        inProcess: state.global.inProcess,
        cartAccess: state.global.cartAccess,
    };
};

export default connect(stateMap)(CheckoutUserDetailContainer);
