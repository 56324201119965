import React, { Component } from 'react';

import { NavLink  } from 'react-router-dom'
import LogoutButton from './logout-button'
import { TO_CUSTOMER_ACCOUNT, TO_CUSTOMER_ORDERS, TO_CHANGE_PASSWORD, TO_RETURN } from '../helpers/routesConstants';
import {generateBreadcrumbs} from '../helpers/utility';
import {myAccountBreadCrumbs, customerOrdersBreadCrumb, customerChangePasswordBreadCrumb} from '../helpers/constants';

export default class CustomerSectionTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumb: null,
            isMobile: false,
            isTab: false
        }
        this.handleResize = this.handleResize.bind(this);
    }
    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.onSectionSelect();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    onSectionSelect = () => {
        const lastWord = window.location.pathname.split('/').pop();
        switch (lastWord) {
            case "account":
                this.setState({breadcrumb: myAccountBreadCrumbs })
                break;
            case "orders":
                this.setState({breadcrumb: customerOrdersBreadCrumb })
                break;
            case "change-password":
                this.setState({breadcrumb: customerChangePasswordBreadCrumb })
                break;                
            default:
                break;
        }
    }
    handleResize() {
        this.setState({
          isMobile: window.innerWidth <= 1199,
          isTab: window.innerWidth <= 991
        });
    }
    render() {
        const {breadcrumb,isMobile,isTab} = this.state;
        return (
            <div className="panel panel-default sidebar-menu customer-section-tabs">
                {isMobile && isTab && (<p className="product-map-arrow">{breadcrumb}</p>)}
                {isMobile && !isTab && (<p className="product-map-arrow" style={{ marginTop: "25px" }}>{breadcrumb}</p>)}
                <div className="panel-heading">
                    <h2 className="h4 panel-title">Customer section</h2>
                </div>
                <div className="panel-body">
                    <ul className="nav nav-pills flex-column text-sm">
                    <li onClick={() => this.onSectionSelect()} className="nav-item"><NavLink activeClassName='active' to={TO_CUSTOMER_ACCOUNT} className="nav-link"><i className="fa fa-user"></i> My Account</NavLink></li>
                        <li onClick={() => this.onSectionSelect()} className="nav-item"><NavLink activeClassName='active' to={TO_CUSTOMER_ORDERS} className="nav-link"><i className="fa fa-list"></i> My Orders</NavLink></li>
                        <li onClick={() => this.onSectionSelect()} className="nav-item"><NavLink activeClassName='active' to={TO_CHANGE_PASSWORD} className="nav-link"><i className="fa fa-lock"></i> Change Password</NavLink></li>
                        <li className="nav-item"><NavLink activeClassName='active' to={TO_RETURN} className="nav-link"><i className="fa fa-undo"></i> Return Items</NavLink></li>
                        {/* <li className="nav-item"><Link activeClassName='active' to={TO_LOGOUT} className="nav-link"><i className="fa fa-power-off"></i> Logout</Link></li> */}
                        <li className="nav-item"><LogoutButton history={this.props.history} className="nav-link" text="Logout" /></li>
                    </ul>
                </div>
            </div>
        )
    }
}
